import { Suspense } from 'react';
import SideMenuStoreProvider from 'src/stores/SideMenuStore';
import MobileNavigationStoreProvider from 'src/stores/MobileNavigationStore';
import PageStoreProvider from 'src/stores/PageStore';
import LoginCallbackRedirection from 'src/components/LoginCallbackRedirection';
import DesignSevenVerification from 'src/components/DesignSevenVerification';
import UserLanguage from 'src/components/UserLanguage';
import RefetchControl from 'src/components/RefetchControl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Spinner, LinkProvider, LocaleProvider } from '@timelog/ui-library';
import MainRouter from 'src/routers/MainRouter';
import DesignSevenReroute from 'src/routers/RedirectDesignSeven';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isDev } from 'src/utils/env';
import { DefaultLink } from 'src/components/DefaultLink';
import styles from './App.module.scss';
import { useGetSiteLocale } from './apis/indexAPI';
import { ToastContainer } from './components/ToastContainer';

declare global {
  interface Window {
    timelogcustomersegmentation: any;
  }
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: true,
      },
    },
  });

  const useGetLocale = () => {
    const { siteLocale } = useGetSiteLocale();

    return siteLocale || 'en-GB';
  };

  return (
    <LocaleProvider value={{ getLocale: useGetLocale }}>
      <LinkProvider value={DefaultLink}>
        <QueryClientProvider client={queryClient}>
          <Suspense
            fallback={
              <div className={styles.Spinner}>
                <Spinner />
              </div>
            }
          >
            <SideMenuStoreProvider>
              <MobileNavigationStoreProvider>
                <PageStoreProvider>
                  <RefetchControl />
                  <LoginCallbackRedirection />
                  <DesignSevenVerification />
                  <UserLanguage />
                  <MainRouter />
                  <DesignSevenReroute />
                  <ToastContainer />
                </PageStoreProvider>
              </MobileNavigationStoreProvider>
            </SideMenuStoreProvider>
          </Suspense>
          {isDev && <ReactQueryDevtools />}
        </QueryClientProvider>
      </LinkProvider>
    </LocaleProvider>
  );
};

export default App;
