import { ButtonHTMLAttributes, forwardRef, AnchorHTMLAttributes } from 'react';
import { TIconButtonSize } from 'assets/js/variables/button';
import { TIconSizes } from 'assets/js/variables/size';
import { TIconName } from 'assets/js/variables/icon';
import { TStatus } from 'assets/js/variables/status';
import { Icon } from 'components/Icon';
import { Avatar } from 'components/Avatar';
import {
  IconButtonBase,
  IconButtonBaseSharedProps,
  IconButtonAnchorBaseSharedProps,
} from './IconButtonBase';

export interface IconButtonProps
  extends Omit<IconButtonBaseSharedProps, 'as' | 'children'>,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  /** Size of the icon */
  iconSize?: TIconButtonSize;
  tone?: TStatus;
  iconName: TIconName;
}

type IconButtonRef = HTMLButtonElement;
export const IconButtonSizeMapping: Record<TIconButtonSize, TIconSizes> = {
  tiny: 'small',
  compact: 'small',
  default: 'normal',
  large: 'large',
};

export const IconButton = forwardRef<IconButtonRef, IconButtonProps>(
  ({ iconSize = 'default', iconName, tone, ...props }, ref) => (
    <IconButtonBase as="button" type="button" ref={ref} size={iconSize} {...props}>
      <Icon
        iconName={iconName}
        size={IconButtonSizeMapping[iconSize]}
        tone={tone}
        aria-hidden="true"
      />
    </IconButtonBase>
  ),
);

export interface AvatarButtonProps extends Omit<IconButtonProps, 'icon' | 'iconName' | 'tone'> {
  avatar: string;
}

export const AvatarButton = forwardRef<IconButtonRef, AvatarButtonProps>(
  ({ avatar, iconSize = 'default', ...props }, ref) => (
    <IconButtonBase as="button" type="button" ref={ref} size={iconSize} {...props}>
      <Avatar src={avatar} size={IconButtonSizeMapping[iconSize]} alt="" />
    </IconButtonBase>
  ),
);

// Component for anchor link
export interface IconButtonAnchorProps
  extends Omit<IconButtonAnchorBaseSharedProps, 'as' | 'children'>,
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children' | 'href'> {
  /** Size of the icon */
  iconSize?: TIconButtonSize;
  tone?: TStatus;
  iconName: TIconName;
}

type IconButtonAnchorRef = HTMLAnchorElement;

export const IconButtonAnchor = forwardRef<IconButtonAnchorRef, IconButtonAnchorProps>(
  ({ iconSize = 'default', iconName, tone, ...props }, ref) => (
    <IconButtonBase as="a" ref={ref} size={iconSize} {...props}>
      <Icon
        iconName={iconName}
        size={IconButtonSizeMapping[iconSize]}
        tone={tone}
        aria-hidden="true"
      />
    </IconButtonBase>
  ),
);

export interface AvatarButtonAnchorProps
  extends Omit<IconButtonAnchorProps, 'icon' | 'iconName' | 'tone'> {
  avatar: string;
}

export const AvatarButtonAnchor = forwardRef<IconButtonAnchorRef, AvatarButtonAnchorProps>(
  ({ avatar, iconSize = 'default', ...props }, ref) => (
    <IconButtonBase as="a" ref={ref} size={iconSize} {...props}>
      <Avatar src={avatar} size={IconButtonSizeMapping[iconSize]} alt="" />
    </IconButtonBase>
  ),
);
