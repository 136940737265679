import { Modal, ButtonGhost, Stack } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';

interface ITipsButton {
  widgetKey: 'BillablePercentage' | 'ExternalPercentage' | 'RegistrationAccuracy';
}

interface IContentTips {
  translatedContent: string;
}

const ContentTips = ({ translatedContent }: IContentTips) => (
  <Stack data-automation-id="ContentTipsStack">
    {translatedContent.split('\n').map((text, i) => (
      <p data-automation-id={`ContentParagraph${i}`} key={text.replaceAll(' ', '').slice(0, 10)}>
        {text}
      </p>
    ))}
  </Stack>
);

export const ContentTipsForTest = ContentTips;

const TipsButton = ({ widgetKey }: ITipsButton) => {
  const { t } = useTranslation('frontpage');

  return (
    <Modal>
      <Modal.Trigger as={ButtonGhost} btnIconLeft="lightbulb" data-automation-id="WidgetTipsButton">
        {t('WidgetTipsButton')}
      </Modal.Trigger>
      <Modal.Dialog>
        <Modal.Header
          data-automation-id={`WidgetTipsHeader${widgetKey}`}
          heading={t(`WidgetTipsHeader${widgetKey}`)}
          closeText={t('WidgetTipsModalClose')}
        />
        <Modal.Content data-automation-id={`WidgetTipsContent${widgetKey}`}>
          <ContentTips translatedContent={t(`WidgetTipsContent${widgetKey}`)} />
        </Modal.Content>
      </Modal.Dialog>
    </Modal>
  );
};

export default TipsButton;
