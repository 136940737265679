import { useTranslation } from 'react-i18next';
import { Text } from '@timelog/ui-library';

const NoResults = () => {
  const { t } = useTranslation('frontpage');

  return <Text data-automation-id="NewsFeedNoResults">{t('NewsFeedNoResults')}</Text>;
};

export default NoResults;
