import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Stack, Text, TextLink, VisuallyHidden } from '@timelog/ui-library';

interface INewsFeedItemProps extends HTMLAttributes<HTMLDivElement> {
  heading?: string;
  description?: string;
  link?: string;
}

const NewsFeedItem = ({ heading, description, link, ...props }: INewsFeedItemProps) => {
  const { t } = useTranslation('frontpage');
  return (
    <div {...props}>
      <Stack verticalMargin="xSmall">
        <Heading level={3} as={4} bold data-automation-id="NewsFeedItemHeading">
          {heading}
        </Heading>
        <Text data-automation-id="NewsFeedItemDescription">{description}</Text>
        <TextLink
          href={link}
          iconName="newWindow"
          target="_blank"
          rel="noopener"
          data-automation-id="NewsFeedItemLink"
        >
          {t('NewsFeedItemLinkText')}
          <VisuallyHidden>({heading})</VisuallyHidden>
        </TextLink>
      </Stack>
    </div>
  );
};

export default NewsFeedItem;
