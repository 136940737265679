import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGhost, Flex, Stack } from '@timelog/ui-library';
import WidgetHeader from 'src/components/WidgetHeader';
import { useGetNewsFeed } from 'src/apis/newsFeedAPI';
import { Loading } from './components/Loading';
import { NewsFeedItem } from './components/NewsFeedItem';
import { Error } from './components/Error';
import { NoResults } from './components/NoResults';
import { INewsFeedItemProps } from './types';
import styles from './NewsFeed.module.scss';

const NewsFeedResult = () => {
  const { isLoading, isError, newsFeedRow, hasMoreThan3News } = useGetNewsFeed();
  const [hasLoadedAll, setHasLoadedAll] = useState<boolean>(hasMoreThan3News);
  const { t } = useTranslation('frontpage');

  if (isLoading) {
    return <Loading className={styles.news} />;
  }
  if (isError) {
    return <Error />;
  }
  if (newsFeedRow?.items?.length === 0) {
    return <NoResults />;
  }
  if (newsFeedRow?.items && newsFeedRow?.items?.length > 0) {
    return (
      <>
        <Stack verticalMargin="large" data-automation-id="NewsFeedItems">
          {newsFeedRow.items?.map(({ title, link, description }: INewsFeedItemProps, i) => (
            <NewsFeedItem
              className={cx(styles.news, { [styles.show3News]: !hasLoadedAll })}
              heading={title}
              description={description}
              link={link}
              key={title}
              data-automation-id={`NewsFeedItem${i}`}
            />
          ))}
        </Stack>
        {!hasLoadedAll && (
          <Flex horizontalAlignment="right">
            <ButtonGhost
              size="compact"
              onClick={() => setHasLoadedAll(true)}
              data-automation-id="NewsFeedLoadMoreButton"
            >
              {t('NewsFeedLoadMoreButton')}
            </ButtonGhost>
          </Flex>
        )}
      </>
    );
  }
  return <Loading className={styles.news} />;
};

const NewsFeed = () => {
  const { t } = useTranslation('frontpage');

  return (
    <Stack verticalMargin="large">
      <WidgetHeader
        widgetKey="NewsFeed"
        heading={t('NewsFeedHeading')}
        headingAs={2}
        iconName="bullhorn"
      />
      <NewsFeedResult />
    </Stack>
  );
};

export default NewsFeed;
