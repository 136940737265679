import { Switch, Route } from 'react-router-dom';
import SystemAdministration from 'src/screens/SystemAdministration';
import PageSettings from 'src/screens/PageSettings';
import Home from 'src/screens/Home';
import Report from 'src/screens/Report';
import PersonalSettings from 'src/screens/PersonalSettings';
import LayoutPage from 'src/components/LayoutPage';
import LegacyPage from 'src/components/LegacyPage';
import ScreenHeader from 'src/components/ScreenHeader';
import { APP_URL } from 'src/consts/cookies';
import TestRoute from 'src/routers/TestRoute';
import PageRoute from 'src/routers/PageRoute';
import LegacyRoute from 'src/routers/LegacyRoute';
import SamplePathRoute from 'src/routers/SamplePathRoute';
import { isDev } from 'src/utils/env';
import {
  systemAdministrationRoutes,
  personalSettingsRoutes,
  pageSettingsRoutes,
  editProjectTemplateRoutes,
} from 'src/consts/paths';
import Cookies from 'js-cookie';
import { designSevenPagePath } from 'src/designSevenPagePath';
import AuthRoute from '../AuthRoute';
import EmployeesRouter from '../EmployeesRouter';

export const administrativeRoutes = systemAdministrationRoutes
  .concat(personalSettingsRoutes)
  .concat(pageSettingsRoutes);

export const MainRouter = () => (
  <Switch>
    {personalSettingsRoutes?.map((url) => (
      <AuthRoute key={url} path={url}>
        <PersonalSettings />
      </AuthRoute>
    ))}

    {/* Must be put before system administration route for edit project template */}
    {editProjectTemplateRoutes?.map((url) => (
      <AuthRoute key={url} path={url}>
        <LayoutPage>
          <LegacyPage automationId="LegacyPage" />
        </LayoutPage>
      </AuthRoute>
    ))}

    {systemAdministrationRoutes?.map((url) => (
      <AuthRoute key={url} path={url}>
        <SystemAdministration />
      </AuthRoute>
    ))}

    {pageSettingsRoutes?.map((url) => (
      <AuthRoute key={url} path={url}>
        <PageSettings />
      </AuthRoute>
    ))}

    {isDev && (
      <Route path="/test">
        <TestRoute />
      </Route>
    )}

    {isDev && (
      <Route path={designSevenPagePath.sample}>
        <LayoutPage>
          <SamplePathRoute />
        </LayoutPage>
      </Route>
    )}

    {/* Route for log out */}
    <Route
      path="/logoff"
      render={() => {
        window.location.href = `${Cookies.get(APP_URL)}/authentication/logoff`;
        return null;
      }}
    />

    <AuthRoute path="/*">
      <LayoutPage>
        <Switch>
          <PageRoute
            pageTitle="Home"
            pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800c"
            pageIdentifier="DesignSevenFrontPage"
            key={designSevenPagePath.home}
            path={designSevenPagePath.home}
          >
            <Home />
          </PageRoute>
          <PageRoute
            pageTitle="Reports"
            pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800c"
            pageIdentifier="DesignSevenReports"
            key={designSevenPagePath.reports}
            path={designSevenPagePath.reports}
          >
            <>
              <ScreenHeader />
              <Report />
            </>
          </PageRoute>
          <Route key={designSevenPagePath.employees} path={designSevenPagePath.employees}>
            <EmployeesRouter />
          </Route>
          {/* Routes for old pages from TLP */}
          <LegacyRoute path="/*">
            <LegacyPage automationId="LegacyPage" />
          </LegacyRoute>
        </Switch>
      </LayoutPage>
    </AuthRoute>
  </Switch>
);
