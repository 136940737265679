import { Chip } from '@timelog/ui-library';
import { useGetSiteStatus } from 'src/apis/indexAPI';

import siteStatusStyle from './SiteStatus.module.scss';
import styles from '../../Navigation.module.scss';

export const SiteStatus = () => {
  const { siteStatus } = useGetSiteStatus();

  return siteStatus && siteStatus === 'Sandbox' ? (
    <li>
      <div className={`${styles.ParentLink} ${siteStatusStyle.SiteStatusIndicator}`}>
        <Chip label={siteStatus} tone="warning" />
      </div>
    </li>
  ) : null;
};
