import { HTMLAttributes } from 'react';
import { Heading, Shimmer, Stack, Text } from '@timelog/ui-library';

const Loading = ({ ...props }: HTMLAttributes<HTMLDivElement>) => (
  <Stack verticalMargin="xSmall" data-automation-id="NewsFeedLoading" {...props}>
    <Heading level={3} bold>
      <Shimmer width="quarter" />
    </Heading>
    <Text>
      <Shimmer />
    </Text>
    <Shimmer width="half" />
  </Stack>
);

export default Loading;
