import { FC } from 'react';
import cx from 'classnames';

import { TChipKind, TChipVariant, TChipSize } from 'assets/js/variables/chip';
import styles from './Chip.module.scss';

interface ChipProps {
  label: string;
  tone?: TChipKind;
  variant?: TChipVariant;
  size?: TChipSize;
}

export const Chip: FC<ChipProps> = ({
  label,
  tone = 'default',
  variant = 'filled',
  size = 'medium',
  ...props
}) => (
  <div
    className={cx(styles.chip, 'chip', {
      [styles[`tone_${tone}`]]: tone,
      [styles[`variant_${variant}`]]: variant,
      [styles[`size_${size}`]]: size,
    })}
    {...props}
  >
    <span>{label}</span>
  </div>
);
