import { format } from 'date-fns';
import Cookies from 'js-cookie';
import { APP_URL } from 'src/consts/cookies';

export const getUserAvatar = (userId: string) =>
  `${Cookies.get(
    APP_URL,
  )}/Settings/UserSettings/DisplayProfilePicture?userID=${userId}&refresh=False&timespan=${format(
    new Date(),
    'yyyyMMddhh',
  )}`;
