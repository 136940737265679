import { Text, Heading, ButtonPrimary } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { isTest } from 'src/utils/env';
import styles from './WidgetFeedback.module.scss';

export default () => {
  const { t } = useTranslation('frontpage');
  return (
    <div className={styles.widgetContainer}>
      <Heading level={2} bold className={styles.title} data-automation-id="FeedbackWidgetTitle">
        {t('FeedbackWidgetTitle')}
      </Heading>
      {!isTest && (
        <iframe
          loading="lazy"
          data-automation-id="FeedbackWidgetVideo"
          className={styles.video}
          src={t('FeedbackWidgetVideo')}
          title="Timelog Feedback"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      <div className={styles.context}>
        <Text data-automation-id="FeedbackWidgetContext">{t('FeedbackWidgetContext')}</Text>
      </div>
      <div className={styles.button}>
        <ButtonPrimary data-automation-id="FeedbackWidgetButton">
          {t('FeedbackWidgetButton')}
        </ButtonPrimary>
      </div>
    </div>
  );
};
