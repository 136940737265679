import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { INewsFeedProps } from 'src/apis/types/feedNewsAPI';

const FEED_NEWS_URL_PATH = '/api/v2/feed';
export const FEED_NEWS_MSW_STATUS_KEY = 'RSS_FEED';

export const FEED_NEWS_KEY = 'FEED_NEWS';

const getNewsFeed = (): Promise<Document> =>
  getFetch({ path: FEED_NEWS_URL_PATH, key: FEED_NEWS_MSW_STATUS_KEY, responseType: 'xml' });

const generateNewsFeedObject = (data?: Document): INewsFeedProps => {
  const newsFeed: INewsFeedProps = { items: [] };

  if (data) {
    const items = data?.querySelectorAll('item');
    const newsFeedRaw = Array.from(items);

    newsFeedRaw.forEach((item) => {
      if (item?.children) {
        const children = Array.from(item.children);
        const title = children.find(({ tagName }) => tagName === 'title')?.innerHTML || '';
        const description =
          children.find(({ tagName }) => tagName === 'description')?.innerHTML || '';
        const link = children.find(({ tagName }) => tagName === 'link')?.innerHTML || '';
        newsFeed.items?.push({
          title,
          description,
          link,
        });
      }
    });
  }
  return newsFeed;
};

export const generateNewsFeedObjectForTest = generateNewsFeedObject;

export const useGetNewsFeed = () => {
  const { data, ...restProps } = useQuery([FEED_NEWS_KEY], getNewsFeed);
  const newsFeedRow = generateNewsFeedObject(data);

  return {
    newsFeedRow,
    hasMoreThan3News: (newsFeedRow?.items && newsFeedRow?.items?.length > 3) || false,
    ...restProps,
  };
};
