import { IconButtonAnchor, Stack } from '@timelog/ui-library';
import FeatureControl from 'src/components/FeatureControl';
import MilestoneFeed from 'src/components/MilestoneFeed';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { NewsFeed } from './components/NewsFeed';
import WidgetFeedback from './components/WidgetFeedback';
import NameArea from './components/NameArea';
import TimeAccuracyWidget from './components/TimeAccuracyWidget';
import BillablePercentageWidget from './components/BillablePercentageWidget';
import ExternalPercentageWidget from './components/ExternalPercentageWidget';

import styles from './Home.module.scss';
import { InternalMessageWidget } from './components/InternalMessageWidget';
import { WidgetGrid } from './components/WidgetGrid';

export const Home = () => {
  const { isValidIndexControlName: hasMilestonesFeatureAccess } =
    useIsValidIndexControlName('Milestones');
  const isPsaProduct = useIsPsaProduct();

  const iconName = hasMilestonesFeatureAccess ? 'flag' : 'bullhorn';

  return (
    <div className={styles.dashboard}>
      <div className={styles.name}>
        <NameArea />
      </div>
      <div className={styles.mobileSidebar}>
        <IconButtonAnchor
          iconName={iconName}
          iconSize="large"
          tooltipText="Feeds"
          href="#sidebar"
          data-automation-id={`DashboardAnchorToSidebar${iconName[0].toUpperCase()}${iconName.substring(
            1,
          )}`}
        />
      </div>
      <div className={styles.main}>
        <WidgetGrid>
          {/* TODO: This should be handled by feature flag from api */}
          {isPsaProduct && (
            <FeatureControl indexControlName="Settings" actionName="GetFrontPageInternalMessage">
              <WidgetGrid.Item columns="4" data-automation-id="WidgetInternalMessageWidget">
                <InternalMessageWidget />
              </WidgetGrid.Item>
            </FeatureControl>
          )}

          <FeatureControl
            indexControlName="WidgetData"
            actionName="GetTimeRegistrationAccuracyInPeriodV2"
          >
            <WidgetGrid.Item columns="1" data-automation-id="WidgetRegistrationAccuracy">
              <TimeAccuracyWidget />
            </WidgetGrid.Item>
          </FeatureControl>

          <FeatureControl
            indexControlName="WidgetData"
            actionName="GetWidgetDataExternalPercentageV2"
          >
            <WidgetGrid.Item columns="1" data-automation-id="WidgetExternalPercentage">
              <ExternalPercentageWidget />
            </WidgetGrid.Item>
          </FeatureControl>

          <FeatureControl
            indexControlName="WidgetData"
            actionName="GetWidgetDataBillablePercentageV2"
          >
            <WidgetGrid.Item columns="1" data-automation-id="WidgetBillablePercentage">
              <BillablePercentageWidget />
            </WidgetGrid.Item>
          </FeatureControl>
          <WidgetGrid.Item columns="4" data-automation-id="Feedback">
            <WidgetFeedback />
          </WidgetGrid.Item>
        </WidgetGrid>
      </div>
      <div id="sidebar" className={styles.sidebar} data-automation-id="DashboardSidebar">
        <Stack verticalMargin="xxLarge">
          <FeatureControl indexControlName="Milestones">
            <MilestoneFeed />
          </FeatureControl>
          <FeatureControl indexControlName="Feed">
            <NewsFeed />
          </FeatureControl>
        </Stack>
      </div>
    </div>
  );
};

export default Home;
