import { FC, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionProps as ReachAccordionProps,
  AccordionItemProps as ReachAccordionItemProps,
  AccordionButtonProps,
  AccordionPanelProps as ReachAccordionPanelProps,
} from '@reach/accordion';
import { Heading, HeadingProps } from 'components/Heading';
import '@reach/accordion/styles.css';

interface AccordionInterface {
  (props: AccordionProps): JSX.Element;
  Item: FC<AccordionItemProps>;
  Trigger: ForwardRefExoticComponent<AccordionTriggerInterface & RefAttributes<HTMLButtonElement>>;
  Panel: FC<AccordionPanelProps>;
}

interface AccordionProps extends ReachAccordionProps {
  className?: string;
}

export const Accordion: AccordionInterface = (props) => <ReachAccordion {...props} />;

interface AccordionItemProps extends ReachAccordionItemProps {
  className?: string;
}

Accordion.Item = (props) => <AccordionItem {...props} />;

interface AccordionTriggerInterface extends AccordionButtonProps, Pick<HeadingProps, 'level'> {
  className?: string;
}

Accordion.Trigger = forwardRef(({ level, children, ...props }, ref) => (
  <Heading level={level}>
    <AccordionButton ref={ref} {...props}>
      {children}
    </AccordionButton>
  </Heading>
));

interface AccordionPanelProps extends ReachAccordionPanelProps {
  className?: string;
}

Accordion.Panel = (props) => <AccordionPanel {...props} />;
