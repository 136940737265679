import { ChangeEvent } from 'react';
import { Flex, Heading, Icon, Select, TIconName } from '@timelog/ui-library';

interface WidgetHeaderProps {
  heading: string;
  widgetKey: string;
  headingAs?: 2 | 3;
  iconName?: TIconName;
  select?: {
    label: string;
    name: string;
    options: Array<{ value: string; name: string }>;
    onChange: (e: ChangeEvent) => void;
  };
}

export default ({
  heading,
  headingAs = 3,
  iconName,
  select,
  widgetKey,
  ...props
}: WidgetHeaderProps) => (
  <Flex horizontalAlignment="justify" verticalAlignment="center" wrap {...props}>
    <Flex>
      {iconName && (
        <Icon
          iconName={iconName}
          size="large"
          data-automation-id={`WidgetHeaderIcon${widgetKey}`}
        />
      )}
      <Heading level={2} as={headingAs} bold data-automation-id={`WidgetHeaderHeading${widgetKey}`}>
        {heading}
      </Heading>
    </Flex>

    {select && (
      <Select
        label={select.label}
        options={select.options}
        name={select.name}
        hiddenLabel
        noBorder
        onChange={select.onChange}
        data-automation-id={`WidgetHeaderSelect${widgetKey}`}
      />
    )}
  </Flex>
);
